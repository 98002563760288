import React from 'react';
import styles from './Partners.module.scss';

// carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// images
import pt1 from './../../../Assets/images/partners/pt1.png';
import pt2 from './../../../Assets/images/partners/pt2.png';
import pt3 from './../../../Assets/images/partners/pt3.png';
import pt4 from './../../../Assets/images/partners/pt4.jpg';
import pt5 from './../../../Assets/images/partners/pt5.jpg';
import pt6 from './../../../Assets/images/partners/pt6.png';

import pt7 from './../../../Assets/images/partners/pt7.jpg';
import pt8 from './../../../Assets/images/partners/pt8.jpg';
import pt9 from './../../../Assets/images/partners/pt9.jpg';
import pt10 from './../../../Assets/images/partners/pt10.jpeg';
import pt11 from './../../../Assets/images/partners/pt11.jpg';
import pt12 from './../../../Assets/images/partners/pt12.svg';
import pt13 from './../../../Assets/images/partners/pt13.jpeg';
import pt14 from './../../../Assets/images/partners/pt14.jpg';
import pt15 from './../../../Assets/images/partners/pt15.png';


const Partners = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div className={styles.partners}>
      <div className={styles.title} id='scrollPartners'>Наши заказчики и партнёры</div>
      <Carousel responsive={responsive} className={styles.carousel} id='scrollPartners'>

        <div className={styles.item}>
          <img style={{height: '100px'}} src={pt8} alt="SetlGroup" />
        </div>

        <div className={styles.item}>
          <img style={{height: '50px'}} src={pt9} alt="Энергоком" />
        </div>

        <div className={styles.item}>
          <img style={{height: '150px'}} src={pt10} alt="Tigers" />
        </div>

        <div className={styles.item}>
          <img style={{height: '150px'}} src={pt6} alt="ООО «ТрансКомИнжиниринг»" />
        </div>


        <div className={styles.item}>
          <img style={{height: '100px'}}  src={pt3} alt="ООО «БАЛТМОР-проект»" />
        </div>

        <div className={styles.item}>
          <img style={{height: '100px'}}  src={pt11} alt="AVTIN" />
        </div>
  
        <div className={styles.item}>
          <img style={{height: '150px'}} src={pt12} alt="ТрансЭнергоснаб" />
        </div>

        <div className={styles.item}>
          <img style={{height: '200px'}} src={pt13} alt="Сетевые энергетические решения" />
        </div>

        <div className={styles.item}>
          <img style={{height: '150px'}} src={pt14} alt="СЭП" />
        </div>

        <div className={styles.item}>
          <img style={{height: '150px'}} src={pt15} alt="Строймонолит" />
        </div>


        <div className={styles.item}>
          <img width="150px"  src={pt7} alt="ООО «Грин Риф»" />
          <i style={{marginBottom: '15px'}}>ООО «Грин Риф»</i>
        </div>

        <div className={styles.item}>
          <img style={{height: '100px', marginBottom: '60px'}}  src={pt1} alt="ОАО «Ультрамар»" />
        </div>

        <div className={styles.item} style={{fontSize: '40px'}}>
          <i>ООО «ТЭС И»</i>
        </div>

        <div className={styles.item}>
          <img style={{height: '125px'}} src={pt2} alt="ОАО «Хотьковский автомост»" />
        </div>

        <div className={styles.item}>
          <img width="150px" src={pt5} alt="ООО «ПСК «СИТИ-Инжиниринг»" />
          <div className={styles.text}>ООО «ПСК «СИТИ-Инжиниринг»</div>
        </div>

        <div className={styles.item}>
          <img width="150px"  src={pt4} alt="ООО «МТ групп»" />
        </div>

      </Carousel>
    </div>
  )
}

export default React.memo(Partners);